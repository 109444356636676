import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';

const Home: React.FC = () => {
  const [qrActive, setQrActive] = useState(false);
  const [data, setData] = useState('');

  const startQrScan = () => {
    setQrActive(true);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Blank</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Blank</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          Hello World<br/>
          <IonButton onClick={() => startQrScan()}>Scan QR</IonButton>
          {qrActive && <>
            <QrReader onResult={(result, error) => {
                  if (!!result) {
                    setData(result?.getText());
                    setQrActive(false);
                  }
                  if (!!error) {
                    console.info(error);
                  }
                }}
                containerStyle={{ width: '100%' }}
                constraints={{ facingMode: 'environment' }}
              />
              
            </>
          }
          <p>{data}</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
